import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import { ComposeBox, ComposeBoxHandle } from './ComposeBox';
import { useRouter } from 'next/navigation';
import { FaTimes } from 'react-icons/fa';
import { VisibilitySelector } from './Visibility';
import { DisplayConceptRef } from '@/lib/api';
import { API_BASE_URL } from '@/lib/common';
import '@/styles/omnisearchbox.css';

interface OmniSearchBoxProps {
  onClose: () => void;
  example_topics?: DisplayConceptRef[];
  example_questions?: string[];
}

export const OmniSearchBox: React.FC<OmniSearchBoxProps> = ({ onClose, example_topics, example_questions }) => {
  const router = useRouter();
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<DisplayConceptRef[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visibility, setVisibility] = useState<'Public' | 'Friends' | 'Private'>('Public');
  const composeBoxRef = useRef<ComposeBoxHandle>(null);
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    // Focus the compose box when the component mounts
    composeBoxRef.current?.focus();
  }, []);

  useLayoutEffect(() => {
    const navbar = document.querySelector('.page-header');
    if (navbar) {
      const height = navbar.getBoundingClientRect().height;
      setNavbarHeight(height);
    }
  }, []);
  const [showVisibilityDropdown, setShowVisibilityDropdown] = useState(false);

  const searchConcepts = useCallback(async (query: string) => {
    if (!query) {
      setSearchResults([]);
      return;
    }
    console.log('Searching for:', query);

    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/schema/prefix_search/${query}`);
      if (!response.ok) {
        throw new Error('Search failed');
      }
      const data: DisplayConceptRef[] = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error searching concepts:', error);
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Debounce search input
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      searchConcepts(searchText);
    }, 300);

    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('keydown', handleEscape);
    };
  }, [searchText, searchConcepts, onClose]);

  return (
    <div 
      className="omnisearch-overlay" 
      onClick={onClose}
      style={{ '--navbar-height': `${navbarHeight}px` } as React.CSSProperties}
    >
      <div 
        className="omnisearch-modal" 
        onClick={e => e.stopPropagation()}
      >
        <div className="omnisearch-container">
          <div className="omnisearch-input-row">
            <ComposeBox
              ref={composeBoxRef}
              onClose={onClose}
              topic=""
              placeholder="Search or ask ..."
              content={searchText}
              onContentChange={setSearchText}
              onExpandedChange={() => {}}
              className="omnisearch"
              show_hints={false}
              show_controls={false}
              initialHeight="5em"
              expandedHeight="5em"
            />
            <button 
              className="omnisearch-close"
              onClick={onClose}
              aria-label="Close search"
            >
              <FaTimes />
            </button>
          </div>
          <div className="omnisearch-results" role="listbox">
            {isLoading ? (
              <div className="omnisearch-message">Searching...</div>
            ) : !searchText ? (
              <div className="omnisearch-message">
                {/* <h2>Find answers</h2> */}
                <h3>Search products</h3>
                <ul className="omnisearch-examples">
                  {example_topics?.map((topic, index) => (
                    <li 
                      key={index}
                      className="omnisearch-result"
                      onClick={() => setSearchText(topic.display)}
                      role="option"
                      tabIndex={0}
                    >
                      &ldquo;{topic.display}&rdquo;
                    </li>
                  ))}
                </ul>
                <h3>Or ask a question</h3>
                <ul className="omnisearch-examples">
                  {example_questions?.map((question, index) => (
                    <li
                      key={index}
                      className="omnisearch-result"
                      onClick={() => setSearchText(question)}
                      role="option"
                      tabIndex={0}
                    >
                      &ldquo;{question}&rdquo;
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <>
                {searchResults.map((result, index) => (
                  <div 
                    key={index} 
                    className="omnisearch-result"
                    onClick={() => {
                      onClose();
                      router.push(`/guide/${encodeURIComponent(result.cref)}`);
                    }}
                    role="option"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        // onClose();
                        router.push(`/guide/${encodeURIComponent(result.cref)}`);
                      }
                    }}
                  >
                    <span className="omnisearch-result-name">
                      {result.display}:{' '}
                    </span>
                    <span className="omnisearch-result-description">
                      {result.overview}
                    </span>
                  </div>
                ))}
                {searchResults.length < 10 && (
                  <div className={`omnisearch-result post-question ${searchResults.length > 0 ? 'has-results' : ''}`}>
                    <span>Post this question to AI, friends, or the community </span>
                    <div className="post-controls">
                      <VisibilitySelector
                        visibility={visibility}
                        setVisibility={setVisibility}
                        showDropdown={showVisibilityDropdown}
                        setShowDropdown={setShowVisibilityDropdown}
                        position="below"
                      />
                      <button 
                        className="controls"
                        onClick={() => {
                          // onClose();
                          composeBoxRef.current?.submit();
                        }}
                      >
                        Post
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
